/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// gatsby-browser.js
import React, { ReactElement } from 'react'
import fetch from 'cross-fetch'
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client'
const cache = new InMemoryCache()
const link = new HttpLink({
  /* Set the endpoint for your GraphQL server, (same as in gatsby-config.js) */
  uri: process.env.GATSBY_SOURCE_WORDPRESS_URL,
  /* Use fetch from cross-fetch to provide replacement for server environment */
  fetch,
})
const client = new ApolloClient({
  link,
  cache,
})
export const wrapRootElement = ({ element }: { element: ReactElement }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
)
